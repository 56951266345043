<template>
<div>
  <b-card class="filter-style">
    <b-card-text>
      <b-row style="align-items: baseline;">
        <b-col
          col
          lg="4"
          md="4"
          >
          <h4>
          Dashboard
          </h4>
          </b-col>
          <b-col
          col
          lg="4"
          md="4"
          >
            <b-form-group
              label="Branch"
            >
              <custom-autopopulate
                  module="branches"
                  label="name"
                  :value="branchId"
                  @changed="branchesChanged"
              />
            </b-form-group>
        </b-col>
        <b-col
        col
        lg="4"
        md="4"
        >
          <b-form-group
                label="Customer"
            >
              <v-select
              id="customerVSelect"
              label="client_name"
              :disabled="showClient"
              :options="clientVal"
              :components="{Deselect}"
              v-model="clientSelectedVal"
              @search="userInput"
              @option:selected="optionChosen"
              @option:deselected="optionRemoved"
              >
                <slot
                v-if="selectedInput.length>3"
                name="no-options"
                >
                Sorry, no matching options.
                </slot>
                <div
                v-else
                slot="no-options"
                >
                Enter at least 4 characters.
                </div>
              </v-select>
            </b-form-group>
        </b-col>
      </b-row>
    </b-card-text>
  </b-card>
  <b-card>
    <b-card-header>
      <b-card-title
        class="pb-2"
      >
      Fleet Service Dockets
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          v-for="item in stats"
          :key="item.icon"
          xl="3"
          sm="6"
          :class="item.customClass"
        ><a @click="callJobsList(item.key)">
          <b-media no-body>
            <b-media-aside

              class="mr-2 numdesign p-1"
            >
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
            </b-media-aside>
            <b-media-body class="my-auto">
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
          </a>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
  <b-card>
    <b-card-header>
      <b-card-title
      class="pb-2"
      >
      Vehicle Inspection Report
      </b-card-title>
    </b-card-header>
      <b-card-body>
        <b-row>
        <b-col
          v-for="item in virStats"
          :key="item.icon"
          xl="3"
          sm="6"
          :class="item.customClass"
        >
          <a @click="callJobsList(item.key)">
          <b-media no-body>
            <b-media-aside

              class="mr-2 numdesign p-1"
            >
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
            </b-media-aside>
            <b-media-body class="my-auto">
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
          </a>
        </b-col>
      </b-row>
      </b-card-body>
  </b-card>
</div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BButton, BCardActions, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import CustomAutopopulate from './common/components/CustomAutopopulate';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormGroup,
    CustomAutopopulate,
    vSelect,
  },
  data() {
    return {
      stats: [
        {
          color: "light-info",
          customClass: "mb-2",
          subtitle: "Open",
          title: "0",
          key: 'draft',
        },
        {
          color: "light-danger",
          customClass: "mb-2",
          subtitle: "Waiting Approval",
          title: "0",
          key: 'pending_review',
        },
        {
          color: "light-success",
          customClass: "mb-2",
          subtitle: "Waiting Order #",
          title: "0",
          key: 'approved',
        },
        {
          color: "light-success",
          customClass: "mb-2",
          subtitle: "Complete",
          title: "0",
          key: 'complete',
        },
      ],
      branchId: 0,
      clientVal: [],
      showClient: true,
      selectedInput: '',
      clientName: [],
      clientId: [],
      virStats: [
        {
          color: "light-success",
          customClass: "mb-2",
          subtitle: "Vir Submitted",
          title: "0",
          key: 'vir_submitted',
        },
      ],
      Deselect: {
        render: createElement => createElement('span', 'X'),
      },
      clientSelectedVal: null,
    }
  },
  created() {
    [this.branchId] = this.$store.state.auth.user.branch_id.length === 1 ? this.$store.state.auth.user.branch_id : [0]
    const submitData = this.$store.state.auth.user.branch_id.length === 1 ? { branch_id: this.branchId } : ''
    this.getData(submitData);
    if (this.$store.state.auth.user.roles[0].id >= 4) {
      // this.branchId = this.$store.state.auth.user.branch_id[0]
      this.showClient = false
      // this.getData({ branch_id: this.branchId });
    }
    this.$store.dispatch('auth/attempt');
    const ability = this.$store.state.auth.user.permissions.map((item) => {
      const [subject, action] = item.split('-');
      return { subject, action };
    });
    console.log(this.$ability);
    this.$ability.update(ability);
  },
  watch: {
    clientSelectedVal(val) {
      console.log('watch', val)
      if (val === null) {
        if (this.branchId) {
          this.clientId = []
          this.getData({ branch_id: this.branchId })
        } else {
          this.getData('')
        }
      }
    },
  },
  methods: {
    async getData(param) {
      let response = {}
      if (param === '') {
        response = await this.$axios
        .get(`/dashboards/index`)
        .catch(err => {
            console.error(err);
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Oops! Something went wrong',
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                },
            });
        });
      } else {
        response = await this.$axios
        .post(`/dashboards/index`, param)
        .catch(err => {
            console.error(err);
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Oops! Something went wrong',
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                },
            });
        });
      }
      if (response.data.status) {
        this.stats.forEach((item, inx) => {
          // eslint-disable-next-line no-param-reassign
          item.title = response.data.count_array.find(itm => itm.status === item.key) ? response.data.count_array.find(itm => itm.status === item.key).total : 0;
        });
        // this.virStats[0].title = response.data.count_array.find(itm => itm.status === 'vir_submitted').total
        const virCheck = response.data.count_array.find(itm => itm.status === 'vir_submitted')
        if (virCheck) {
          this.virStats[0].title = virCheck.total
        } else {
          this.virStats[0].title = 0
        }
      }
    },
    branchesChanged(val) {
      console.log(val);
      if (val > 0) {
        this.clientVal = [];
        this.clientName = '';
        this.branchId = val;
        this.showClient = false;
        this.getData({ branch_id: this.branchId });
      } else {
        this.showClient = true;
        this.getData('')
      }
    },
    userInput(item) {
      this.selectedInput = item
      if (item.length > 3) {
        this.$axios.post('/dashboards/getClient', { search: item, branch_id: this.branchId })
          .then(res => {
              this.$helper.hideLoading()
              if (res.data) {
                this.clientVal = res.data
                this.showClient = false
              }
          })
          .catch(err => {
              this.$helper.hideLoading();
              this.$toast({
                  component: ToastificationContent,
                  props: {
                      title: 'Oops! Something went wrong',
                      icon: 'AlertCircleIcon',
                      variant: 'danger',
                  },
              });
          })
      }
    },
    optionChosen(val) {
      if (val) {
          this.clientId = [val.id]
          this.clientName = val.client_name
          this.getData({ client_id: this.clientId })
      }
    },
    optionRemoved(val) {
      console.log('hi', val, this.clientId)
      if (this.clientId.length > 0) {
        this.clientId = []
        this.getData({ branch_id: this.branchId })
      } else {
        this.getData('')
      }
    },
    callJobsList(code) {
      console.log('test: ', code)
      let clntId = 0
      if (this.clientId.length > 0) {
        [clntId] = this.clientId
      }
      if (code === 'complete') {
        this.$router.push({
          path: '/account/jobs/complete',
          query: {
            status: code,
            bid: this.branchId,
            cid: clntId,
            name: this.clientName,
          },
        })
      } else {
        this.$router.push({
          path: '/account/jobs',
          query: {
            status: code,
            bid: this.branchId,
            cid: clntId,
            name: this.clientName,
          },
        })
      }
    },
  },
}
</script>
<style scoped>
.media {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  border-radius: 10px;
}
.numdesign {
  background-color: #143787;
  border-radius: 10px 0 0 10px;
}
h4.font-weight-bolder.mb-0 {
  color: #fff;
}
.filter-style .card-body {
  padding: 1rem 1.5rem 0rem 1.5rem!important;
}
</style>
